export const ActionType = {
    GET_COUNTRIES_ARRAY_START: "GET_COUNTRIES_ARRAY_START",
    GET_COUNTRIES_ARRAY_SUCCESS: "GET_COUNTRIES_ARRAY_SUCCESS",
    GET_COUNTRIES_ARRAY_ERRORS: "GET_COUNTRIES_ARRAY_ERRORS",
    GET_REGIONS_ARRAY_START: "GET_REGIONS_ARRAY_START",
    GET_REGIONS_ARRAY_SUCCESS: "GET_REGIONS_ARRAY_SUCCESS",
    GET_REGIONS_ARRAY_ERRORS: "GET_REGIONS_ARRAY_ERRORS",
    GET_RESORT_ARRAY_START: "GET_RESORT_ARRAY_START",
    GET_RESORT_ARRAY_SUCCESS: "GET_RESORT_ARRAY_SUCCESS",
    GET_RESORT_ARRAY_ERRORS: "GET_RESORT_ARRAY_ERRORS"
};
