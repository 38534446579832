import { ActionType } from '../constants/colorpicker'

const initialState = {
  color: '#FC2A3A',
  opened: false,
}

const colorpicker = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_COLOR:
      return {
        ...state,
        color: action.payload,
      }
    case ActionType.OPEN_COLOR_PICKER:
      return {
        ...state,
        opened: true,
      }
    case ActionType.CLOSE_COLOR_PICKER:
      return {
        ...state,
        opened: false,
      }
    default:
      return state
  }
}

export default colorpicker
