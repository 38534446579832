import { ActionType } from "../constants/auth";

const initialState = {
  loading: false,
  me: {},
  errors: {}
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.AUTH_HANDLE_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.AUTH_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.AUTH_SUCESS:
      return {
        ...state,
        loading: false,
        me: action.payload,
        errors: {}
      };
    case ActionType.AUTH_LOGOUT:
      return {
        ...state,
        me: {}
      };
    case ActionType.AUTH_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.REGISTER_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {}
      };
    case ActionType.REGISTER_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.FOGOT_PASSWORD_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.FOGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {}
      };
    case ActionType.FOGOT_PASSWORD_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.RESET_PASSWORD_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ActionType.RESET_PASSWORD_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.CLEAN_AUTH_DATA:
      return {
        ...state,
        me: {},
        errors: {}
      };
    case ActionType.GET_ME_DATA:
    return {
      ...state,
      me: action.payload
    }
    case ActionType.GET_HELPER_DATA:
    return {
      ...state,
      show_helper: action.payload
    }
    default:
      return state;
  }
};

export default auth;
