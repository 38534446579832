import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import resortsReducer from "./reducers/resorts";
import mapReducer from "./reducers/map";
import newCoordsReducer from "./reducers/coordinates";
import general from "./reducers/general";
import coords from "./reducers/existingCoords";
import modalsReducer from "./reducers/modalsReducer";
import auth from "./reducers/auth";
import instrumentsReducer from "./reducers/instruments";
import marks from "./reducers/marks";
import colorpicker from "./reducers/colorpicker";
import mapCharacteqristicsReducer from "./reducers/mapCharacteristics";
import likesAndComments from "./reducers/likeAndComments";

// Middleware
const middleware = [thunk];
const enhancer = applyMiddleware(...middleware);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"]
};

const rootReducer = combineReducers({
  modals: modalsReducer,
  auth,
  resorts: resortsReducer,
  map: mapReducer,
  instruments: instrumentsReducer,
  newCoords: newCoordsReducer,
  general,
  coords,
  marks,
  colorpicker,
  characteristics: mapCharacteqristicsReducer,
  likes: likesAndComments
});

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(enhancer)
);

export default store;
