import { ActionType } from "../constants/instruments";

const initialState = {
  instrument: "",
  zoomed: true
};

const instrumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.OPEND_DRAW_INSTRUMENT:
      const zoomed = action.payload === "grabbing" ? true : false;
      return {
        ...state,
        instrument: action.payload,
        zoomed: zoomed
      };
    default:
      return state;
  }
};

export default instrumentsReducer;
