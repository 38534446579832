export const ActionType = {
  ADD_COORD: "ADD_COORD",
  EDIT_COORD: "EDIT_COORD",
  REMOVE_LAST_ITEM: "REMOVE_LAST_ITEM",
  ADD_DATA: "ADD_DATA",
  ADD_MARKS_START: "ADD_MARKS_START",
  ADD_MARKS_SUCCESS: "ADD_MARKS_SUCCESS",
  ADD_MARKS_ERRORS: "ADD_MARKS_ERRORS",
  ADD_TEMP_MARKS_START: "ADD_TEMP_MARKS_START",
  ADD_TEMP_MARKS_SUCCESS: "ADD_TEMP_MARKS_SUCCESS",
  ADD_TEMP_MARKS_ERRORS: "ADD_TEMP_MARKS_ERRORS",
  REMOVE_TEMP_MARKS_START: "REMOVE_TEMP_MARKS_START",
  REMOVE_TEMP_MARKS_SUCCESS: "REMOVE_TEMP_MARKS_SUCCESS",
  REMOVE_TEMP_MARKS_ERROR: "REMOVE_TEMP_MARKS_ERROR",
  SHOW_MARK_START: "SHOW_MARK_START",
  SHOW_MARK_SUCCESS: "SHOW_MARK_SUCCESS",
  SHOW_MARK_ERROR: "SHOW_MARK_ERROR",
  SHOW_ALL_MARKS_START: "SHOW_ALL_MARKS_START",
  SHOW_ALL_MARKS_SUCCESS: "SHOW_ALL_MARKS_SUCCESS",
  SHOW_ALL_MARKS_ERROR: "SHOW_ALL_MARKS_ERROR",
  DELETE_POINT_FROM_MODAL: "DELETE_POINT_FROM_MODAL",
  DELETE_ERRORS: "DELETE_ERRORS",
  REMOVE_ERRORS: "REMOVE_ERRORS",
  ADD_TEMP_ID: "ADD_TEMP_ID",
  REMOVE_COORDS_DATA: "REMOVE_COORDS_DATA"
};
