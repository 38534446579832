import { ActionType } from "../constants/map";

const initialState = {
  loading: false,
  errors: {},
  map: null,
  color: "#ff6200",
  crumbs: {}
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_MAP_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        map: action.payload.item
      };
    case ActionType.GET_MAP_ERROR:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.DELETE_MAP:
      return {
        ...state,
        map: null
      };
    case ActionType.SET_COLOR:
      return {
        ...state,
        color: action.payload
      };
    case ActionType.SET_DETAILS:
      return {
        ...state,
        crumbs: action.payload
      };
    default:
      return state;
  }
};

export default mapReducer;
