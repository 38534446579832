import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import App from "./App";
import { ToastContainer } from "react-toastify";
import Notifications from "react-notify-toast";

const pStore = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate persistor={pStore}>
        <Notifications options={{ zIndex: 200, top: "50px" }} />
        <App />
      </PersistGate>
    </Router>
    <ToastContainer autoClose={2000} />
  </Provider>,
  document.getElementById("root")
);
