import { ActionType } from "../constants/likeAndComments";

const initialState = {
  loading: false,
  comments: [],
  errors: {},
  comment: "",
  commentsId: null
};

const likesAndComments = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.ADD_LIKE_DISLIKE:
      return {
        ...state
      };
    case ActionType.ADD_COMMENT_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.ADD_COMMENT:
      return {
        ...state,
        comment: ""
      };
    case ActionType.ADD_COMMENT_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_COMMENTS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: action.payload,
        commentsId: action.id
      };
    case ActionType.GET_COMMENTS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.DELETE_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case ActionType.WRITE_COMMENT:
      return {
        ...state,
        comment: action.payload
      };
    default:
      return state;
  }
};

export default likesAndComments;
