export const ActionType = {
  ADD_LIKE_DISLIKE: "ADD_LIKE_DISLIKE",
  ADD_LIKE_DISLIKE_ERRORS: "ADD_LIKE_DISLIKE_ERRORS",
  ADD_COMMENT: "ADD_COMMENT",
  ADD_COMMENT_ERRORS: "ADD_COMMENT_ERRORS",
  GET_COMMENTS_START: "GET_COMMENTS_START",
  GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
  GET_COMMENTS_ERRORS: "GET_COMMENTS_ERRORS",
  DELETE_ERRORS: "DELETE_ERRORS",
  WRITE_COMMENT: "WRITE_COMMENT"
};
