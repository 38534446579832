import { ActionType } from "../constants/general";

const initialState = {
  loading: false,
  tags: [],
  errors: {}
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_TAGS_ARRAY_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_TAGS_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.payload
      };
    case ActionType.GET_TAGS_ARRAY_ERROR:
      return {
        ...state,
        errors: action.payload
      };
    default:
        return state;
  }
};

export default general;
