import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./scss/main.scss";

// PAGES
import MainPage from "./pages/MainPage/Loadable";
import MapPage from "./pages/MapPage/Loadable";

import { detectPageMeta } from './helpers/seo'

const App = () => {
  const location = useLocation()

  useEffect( () => {
    const pageMeta = detectPageMeta(location)
    document.title = pageMeta.title
    document.querySelector('meta[name=description]')
      .setAttribute('content', pageMeta.description)
  }, [location])

  return (
    <Switch>
      <Route path="/" exact component={MainPage} />
      <Route path="/password/reset" component={MainPage} />
      <Route
        path="/password/reset"
        render={routeProps => <MainPage reset_password={true} {...routeProps} />}
      />
      <Route path="/:resort/:resortId/:previewId?" exact component={MapPage} />
      <Route component={() => (<h1 style={{ textAlign: 'center', paddingTop: "150px" }}>Error 404: Page not found</h1>)} />
    </Switch>
  );
};

export default App;
