import { ActionType } from "../constants/coordinates";

const initialState = {

  newCoords: [],
  loading: false,
  send: false,
  errors: {},
  existingCoords: [],
  temp_id: null
};

const newCoordsReducer = (state = initialState, action) => {
  let coordsCopy = state.newCoords && state.newCoords.slice();
  switch (action.type) {
    case ActionType.ADD_COORD:
      coordsCopy.push(action.payload);
      return {
        ...state,
        newCoords: coordsCopy
      };
    case ActionType.EDIT_COORD:
      return {
        ...state,
        newCoords: action.payload
      };
    case ActionType.REMOVE_LAST_ITEM:
      let updatedData =
        state.newCoords.length >= 2 ? state.newCoords.slice(0, -1) : [];
      return {
        ...state,
        newCoords: updatedData
      };
    case ActionType.ADD_TEMP_MARKS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.ADD_TEMP_MARKS_SUCCESS:
      let dataWithId = action.payload;
      const arrayWithId = state.newCoords.map(item => {
        if (
          item.type === dataWithId.meta.type && +item.x === +dataWithId.meta.x && +item.y === +dataWithId.meta.y
        ) {
          item.id = dataWithId.id
        }
        return item
      });
      return {
        ...state,
        loading: false,
        newCoords: arrayWithId
      };
    case ActionType.ADD_TEMP_MARKS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.ADD_MARKS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.ADD_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        newCoords: [],
        send: true
      };
    case ActionType.ADD_MARKS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.REMOVE_TEMP_MARKS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.REMOVE_TEMP_MARKS_SUCCESS:
      let updatedTempraryMarksData =
        state.newCoords.length >= 2 ? state.newCoords.slice(0, -1) : [];
      return {
        ...state,
        loading: false,
        newCoords: updatedTempraryMarksData
      };
    case ActionType.DELETE_POINT_FROM_MODAL:
      let filteredArray = state.newCoords.filter(
        element => element.temp_id !== action.payload.temp_id
      );
      return {
        ...state,
        newCoords: filteredArray
      };
    case ActionType.DELETE_ERRORS:
      const errorsCopy = JSON.parse(JSON.stringify(state.errors));
      if (errorsCopy[action.payload]) {
        delete errorsCopy[action.payload];
      }
      return {
        ...state,
        errors: errorsCopy
      };
    case ActionType.REMOVE_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case ActionType.ADD_TEMP_ID:
      return {
        ...state,
        temp_id: Date.now()
      };
    case ActionType.REMOVE_COORDS_DATA:
      return {
        ...state,
        newCoords: [],
        loading: false,
        send: false,
        errors: {},
        existingCoords: [],
        temp_id: null
      }
    default:
      return state;
  }
};

export default newCoordsReducer;
