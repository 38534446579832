import { ActionType } from "../constants/existingCoords";

const initialState = {
  loading: false,
  existingCoords: [],
  previewCoords: [],
  submissionId: null,
  isPreview: false,
  errors: {}
};

const coords = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_ALL_MAP_MARKS_START:
      return {
        ...state,
        loading: true,
        isPreview: false,
      };
    case ActionType.GET_MARK_PREVIEW_START:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_ALL_MAP_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        existingCoords: action.payload
      };
    case ActionType.GET_MARK_PREVIEW_SUCCESS:
      const { items, submission_id } = action.payload
      return {
        ...state,
        previewCoords: items,
        submissionId: submission_id,
        loading: false,
      };
    case ActionType.SET_MARK_PREVIEW:
      return {
        ...state,
        isPreview: action.payload,
      };
    case ActionType.GET_ALL_MAP_MARKS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_MARK_PREVIEW_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default coords;
