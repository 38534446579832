export const ActionType = {
  GET_CHARACTERISTICS_START: "GET_CHARACTERISTICS_START",
  GET_CHARACTERISTICS_SUCCESS: "GET_CHARACTERISTICS_SUCCESS",
  GET_CHARACTERISTICS_ERRORS: "GET_CHARACTERISTICS_ERRORS",
  ADD_CHARACTERISTICS_ITEM_START: "ADD_CHARACTERISTICS_ITEM_START",
  ADD_CHARACTERISTICS_ITEM_SUCCESS: "ADD_CHARACTERISTICS_ITEM_SUCCESS",
  ADD_CHARACTERISTICS_ITEM_ERRORS: "ADD_CHARACTERISTICS_ITEM_ERRORS",
  CHANGE_RATE_START: "CHANGE_RATE_START",
  CHANGE_RATE_SUCCESS: "CHANGE_RATE_SUCCESS",
  CHANGE_RATE_ERRORS: "CHANGE_RATE_ERRORS",
  SET_DESCRIPTION_VALUE: "SET_DESCRIPTION_VALUE",
  CLEARE_DESCRIPTION_FIELD: "CLEARE_DESCRIPTION_FIELD",
  DECRIPTION_ERRORS_CLEANER: "DECRIPTION_ERRORS_CLEANER"
};
