import { ActionType } from "../constants/resort";

const initialState = {
  loading: false,
  countries: [],
  regions: [],
  resorts: [],
  errors: {}
};

const resortsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_COUNTRIES_ARRAY_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_COUNTRIES_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload
      };
    case ActionType.GET_COUNTRIES_ARRAY_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_REGIONS_ARRAY_START:
      return {
        ...state, 
        loading: true
      };
    case ActionType.GET_REGIONS_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: action.payload
      };
    case ActionType.GET_REGIONS_ARRAY_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_RESORT_ARRAY_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_RESORT_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        resorts: action.payload
      };
    case ActionType.GET_RESORT_ARRAY_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default resortsReducer;
