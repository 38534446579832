import { ActionType } from "../constants/marks";

const initialState = {
  loading: false,
  mark: null,
  errors: {},
  isOpenSidebar: false
};

const marks = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SHOW_MARK_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.SHOW_MARK_SUCCESS:
      return {
        ...state,
        loading: false,
        mark: action.payload
      };
    case ActionType.SHOW_MARK_ERROR:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.OPEN_SIDEBAR:
      return {
        ...state,
        isOpenSidebar: true
      };
    case ActionType.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpenSidebar: false,
        mark: null,
      }
    default:
      return state;
  }
};

export default marks;
