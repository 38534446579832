export const ActionType = {
  AUTH_START: "AUTH_START",
  AUTH_SUCESS: "AUTH_SUCESS",
  AUTH_ERRORS: "AUTH_ERRORS",
  AUTH_HANDLE_ERRORS: "AUTH_HANDLE_ERRORS",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  REGISTER_START: "REGISTER_START",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERRORS: "REGISTER_ERRORS",
  FOGOT_PASSWORD_START: "FOGOT_PASSWORD_START",
  FOGOT_PASSWORD_SUCCESS: "FOGOT_PASSWORD_SUCCESS",
  FOGOT_PASSWORD_ERRORS: "FOGOT_PASSWORD_ERRORS",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERRORS: "RESET_PASSWORD_ERRORS",
  CLEAN_AUTH_DATA: "CLEAN_AUTH_DATA",
  GET_ME_DATA: "GET_ME_DATA",
  GET_HELPER_DATA: "GET_HELPER_DATA",
};
