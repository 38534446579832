import { ActionType } from '../constants/modalConstants';
import { combineReducers } from 'redux';

const resortModalReducer = (state = false, { type }) => {
  switch (type) {
    case ActionType.OPEN_RESORT_MODAL:
      return true;
    case ActionType.CLOSE_RESORT_MODAL:
      return false;
    default:
      return state;
  }
};

const drawInstrumentsReducer = (state = true, { type }) => {
  switch (type) {
    case ActionType.OPEN_DRAW_INSTRUMENTS:
      return true;
    case ActionType.CLOSE_DRAW_INSTRUMENTS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isResortModalOpen: resortModalReducer,
  isDrawInstrumentsOpen: drawInstrumentsReducer,
});
