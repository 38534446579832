import { ActionType } from "../constants/mapCharacteristics";
import { valueToNode } from "@babel/types";

const initialState = {
  loading: false,
  characteristics: {},
  errors: {},
  description: "",
  page: 1,
  data: []
};

const mapCharacteqristicsReducer = (state = initialState, action) => {
  const characteristicsCopy = JSON.parse(JSON.stringify(state.characteristics));
  switch (action.type) {
    case ActionType.GET_CHARACTERISTICS_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.GET_CHARACTERISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ActionType.GET_CHARACTERISTICS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.ADD_CHARACTERISTICS_ITEM_SUCCESS:
      characteristicsCopy.push(action.payload);
      return {
        ...state,
        characteristics: characteristicsCopy
      };
    case ActionType.ADD_CHARACTERISTICS_ITEM_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.GET_CHARACTERISTICS_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case ActionType.CHANGE_RATE_SUCCESS:
      return {
        ...state
      };
    case ActionType.SET_DESCRIPTION_VALUE:
      return {
        ...state,
        description: action.payload
      };
    case ActionType.CLEARE_DESCRIPTION_FIELD:
      return {
        ...state,
        description: ""
      };
    case ActionType.DECRIPTION_ERRORS_CLEANER:
      return {
        ...state,
        errors: {}
      };
    default:
      return state;
  }
};

export default mapCharacteqristicsReducer;
