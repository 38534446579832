const getPathSectors = location => location.pathname.split('/')

export const isResortPage = location => {
  const pathSectors = getPathSectors(location)
  return pathSectors.length === 3 && !isNaN(Number.parseInt(pathSectors[2]))
}

export const parseSeoResortName = name => {
  const replaceStr = '$?$'
  return name.replaceAll('---', replaceStr).replaceAll('-', ' ').replaceAll(replaceStr, ' - ')
}

export const pagesMeta = {
  default: {
    title: 'Honest Maps - Crowd Sourced Trail Maps, Best Runs',
    description: 'Community sourced Honest Maps to find the best skiing and snowboarding spots. Share the best trails, powder stashes, and make the most of your trip.',
  },
  resort: {
    title: '{$RESORT_NAME$} - Honest Maps - Crowd Sourced Trail Maps, Best Runs',
    description: 'Honest Trail Map of {$RESORT_NAME$} - Community sourced Honest Maps to find the best skiing and snowboarding spots. Share the best trails, powder stashes, and make the most of your trip.',
  }
}

export const getSeoNameFromLocation = location =>
  parseSeoResortName(getPathSectors(location)[1])

export const detectPageMeta = location => {
  if (isResortPage(location)) {
    const pageMeta = {
      ...pagesMeta.resort
    }
    const resortName = getSeoNameFromLocation(location)
    pageMeta.title = pageMeta.title.replaceAll('{$RESORT_NAME$}', resortName)
    pageMeta.description = pageMeta.description.replaceAll('{$RESORT_NAME$}', resortName)

    return pageMeta
  }

  return {
    ...pagesMeta.default
  }
}

export const altMapImage = location => {
  if (!isResortPage(location)) {
    return ''
  }

  const resortName = getSeoNameFromLocation(location)
  return `${resortName} - Honest Maps - Crowd Sourced Trail Maps, Best Runs`
}
